import './css/common.css'
import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

const Auth = lazy(() => import('./pages/Auth'));
const Login = lazy(() => import('./pages/Login'));
const Search = lazy(() => import('./pages/Search'));
const Detail = lazy(() => import('./pages/Detail'));
const JrctDetail = lazy(() => import('./pages/JrctDetail'));
const JapicDetail = lazy(() => import('./pages/JapicDetail'));
const AddStudy = lazy(() => import('./pages/AddStudy'));
const EditStudy = lazy(() => import('./pages/EditStudy'));
const EditStudyNCT = lazy(() => import('./pages/EditStudyNCT'));
const EditApplyStudyNCT = lazy(() => import('./pages/EditApplyStudyNCT'));
const ApproveStudy = lazy(() => import('./pages/ApproveStudy'));
const NotFound = lazy(() => import('./pages/NotFound'));

const SampleCode = lazy(() => import('./pages/SampleCode'));
const EditStudyJAPIC = lazy(() => import('./pages/EditStudyJapic'));

export function App() {
    return (
        <Suspense fallback={null}>
            <Switch>
                <Route exact path='/' component={Auth} />
                <Route exact path='/Login' component={Login} />
                <Route path='/search' component={Search} />
                <Route path='/detail' component={Detail} />
                <Route path='/jrct-detail' component={JrctDetail} />
                <Route path='/japic-detail' component={JapicDetail} />
                <Route path='/edit-study' component={EditStudy} />
                <Route path='/edit-study-nct' component={EditStudyNCT} />
                <Route path='/edit-apply-study-nct' component={EditApplyStudyNCT} />
                <Route path='/approve-study' component={ApproveStudy} />
                <Route path='/add-study' component={AddStudy} />
                <Route path='/sample-code' component={SampleCode} />
                <Route path='/edit-study-japic' component={EditStudyJAPIC} />
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    )
}